import './App.css';


function App() {
  return (
    <div className="App">
      <header className=""  >
        <div className="col-10 col-md-6" style={{position: "absolute",
            borderRadius:"15px",boxShadow:"0 0 15px rgba(170,170,170,0.6)",padding:"15px",
            top: "50%",left:"50%",transform: "translate(-50%, -50%)" }} >
          <h2>Welcome to <span style={{color:"rgb(0, 150, 180)"}} >BeTuned.app</span></h2>
          <h3 className="m-2">Web app under contruction </h3>
          <h4>Stay tuned</h4>
        </div>
      </header>
    </div>
  );
}

export default App;
